if (document.querySelector('#yearSelected')) {
    document.querySelector('#yearSelected').addEventListener("change", function () {
        if (document.querySelector('#productSelected').value) {
            this.form.action = '/statistics/index';
        }

        this.form.submit();
    });
}

if (document.querySelector('#productSelected')) {
    document.querySelector('#productSelected').addEventListener("change", function () {
        if (! this.value) {
            this.form.action = '/statistics/all';
        } else {
            this.form.action = '/statistics/index';
        }

        this.form.submit();
    });
}

if (document.querySelector('#inventoryDate')) {
    document.querySelector('#inventoryDate').addEventListener("change", function () {
        this.form.action = '/inventory/all';
        this.form.submit();
    });
}
